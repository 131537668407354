<template>
    <div class="search">
        <menu-overlay :showMenu="showMenu" :close-action="closeMenu" :chefs="chefFiltered" :services="serviceFiltered" :chef-index="chefIndex" :serviceIndex="serviceIndex" container-class="dialog-fullscreen-overlay"></menu-overlay>

<!--        <v-img-->
<!--                src="../assets/group-people-dining-concept-260nw-495461278_crop.jpg"-->
<!--                class="brighten-v-img "-->
<!--        >-->
          <v-img style="min-height: 285px;"
                       class="brighten-v-img theme--dark"
                       src="../assets/home_1280.webp"
                       :srcset="`${require('../assets/home_1280.webp')}, ${require('../assets/home_900.webp')} 900w, ${require('../assets/home_400.webp')} 400w`"
                >
            <div class="container">
                <v-col>
                    <div class="row">
                        <div class="col"></div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 white--text" style="visibility: visible;">
                            <h1 class="">Find a menu from our selection of chefs</h1>
                            <p class=""></p>
                        </div>
                    </div>
                    <div class="round-corner black-transparent margin-default padding">
                        <v-col fuild>
                            <!--                        <v-col>-->
                            <v-row no-gutters align-content="center" justify="center">
                                <v-col cols="12" sm="3">
                                    <v-autocomplete
                                        id="address"
                                        :no-filter="true"
                                            dark
                                            clearable
                                            v-model="$root.formData.location"
                                            :items="$root.formData.locationItems"
                                            :loading="isLoading"
                                            :search-input.sync="lookupAddress"
                                            color="white"
                                            item-text="place_name"
                                            label="Location"
                                            placeholder="Start typing your address"
                                            prepend-icon="mdi-map-marker"
                                            append-icon=""
                                            return-object
                                            @change="getLocation"
                                            auto-select-first
                                            hide-no-data
                                    ></v-autocomplete>
                                </v-col>

                                <v-col cols="12" sm="3">
                                    <v-menu
                                            v-model="datepicker"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
<!--                                            @input="search"-->
                                            <v-text-field
                                                id="date"
                                                    @input="search"
                                                    v-model="$root.formData.date"
                                                    clearable
                                                    dark
                                                    label="Date"
                                                    prepend-icon="mdi-calendar-range"
                                                    readonly
                                                    v-on="on"
                                                    v-bind="attrs"
                                                    @click:clear="$root.formData.date=undefined"
                                            ></v-text-field>
                                        </template>
<!--                                        @change="search"-->
<!--                                      :allowed-dates="checkAvailableDate"-->
                                        <v-date-picker
                                                :min="tomorrow"
                                                v-model="$root.formData.date"
                                                :events="functionEvents"
                                                @input="datepicker = false"
                                                @change="search"

                                        >
                                          <template v-slot:default>
                                            <!-- legend-->
                                            <div style="width: 45px;height:45px;text-align: center">
                                              <button type="button" style="width:32px;height: 32px" class="v-btn v-btn--text v-btn--rounded theme--light"><div class="v-btn__content " style="margin-bottom: 4px;">0</div><div class="v-date-picker-table__events"><div class="available"></div><div class="unavailable"></div></div></button>
                                            </div>
                                            <div class="caption">
                                              Lunch Available and Dinner unavailable
                                            </div>
                                          </template>
                                      </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" sm="3">
<!--                                    -->
                                    <v-select
                                        id="time"
                                        @change="search"
                                        dark
                                        clearable
                                        prepend-icon="mdi-clock"
                                        :items="availableTimes"
                                        label="Time"
                                        v-model="$root.formData.time"
                                        @click:clear="$root.formData.time=undefined"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" sm="3">
<!--                                    @input="search"-->
                                    <v-text-field
                                        id="guests"
                                            dark
                                            @input="search"
                                            prepend-icon="mdi-account-multiple"
                                            label="Guests"
                                            type="number"
                                            min="2"
                                            max="999"
                                            v-model.number="$root.formData.guests"
                                            :error-messages="errorGuests"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

<!--                            <v-row align-content="center" justify="center">-->
<!--                                <v-btn @click="search">Search</v-btn>-->
<!--                            </v-row>-->
                        </v-col>
                    </div>

                    <div class="row">
                        <div class="col"></div>
                    </div>
                </v-col>
            </div>
        </v-img>
        <div style="height: 0px">
            <v-progress-linear v-if="isLoadingChef"
                               indeterminate
                               height="6"
            ></v-progress-linear>
        </div>
      <div class="container" style="text-align: center" v-if="serviceFiltered">
        <h1 v-if="isLoadingChef" class="primary--text">We are searching for providers matching your criteria. Please wait...</h1>
        <h1 v-if="!isLoadingChef && (serviceFiltered===undefined || serviceFiltered.length==0)" class="primary--text">Sorry, We can't find any chef atching your criteriamatching your criteria!</h1>

<!--        Cef available-->
        <div class="primary--text text-h5" style="font-weight: bold" v-if="this.serviceFiltered.filter(ts=>( ts.available)).length>0">Discover our private Chefs
        </div>

        <div
            v-for="(ts) in this.serviceFiltered.filter(ts=>( ts.available))"
            v-bind:key="ts.id"
            style="display: inline-flex"
        >
        <ChefCard :chefService="ts" @openMenu="openMenu(ts)" @book="book"></ChefCard>
        </div>

<!-- ON REQUEST ONLY-->
        <div class="primary--text text-h5" style="font-weight: bold" v-if="this.serviceFiltered.filter(ts=>( !ts.available)).length>0">Chefs available by request only
        </div>

        <div
            v-for="(ts) in this.serviceFiltered.filter(ts=>( !ts.available))"
            v-bind:key="ts.id"
            style="display: inline-flex"
        >
          <ChefCard :chefService="ts" @openMenu="openMenu(ts)" @book="book"></ChefCard>
        </div>

        <div class="" style="text-align: center" v-if="this.chefFiltered!==undefined">
          <v-divider style="margin-top: 12px;margin-bottom: 12px;" ></v-divider>
          <v-btn class="margin-top-default" color="primary" @click="showLetMeKnow=!showLetMeKnow">Alert Me About New Chefs</v-btn>
        </div>
      </div>


      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
      >
        <div class="container" id="searchResult" style="text-align: center" v-if="this.chefFiltered===undefined || showLetMeKnow==true">
            <div v-if="$store.state.infoMessages!==undefined && $store.state.infoMessages['CHEF_FOUND_LOCATION']!==undefined" >{{$store.state.infoMessages['CHEF_FOUND_LOCATION']}}</div>
            <div v-if="$store.state.infoMessages===undefined || showLetMeKnow==true">
              <h6 class="text-h6">We can let you know when a chef is available near you. Just enter your email and your address.</h6>
                <v-text-field
                    v-model=email
                      label="Email"
                      type="email"
                      :rules="emailRules"
                      required
                ></v-text-field>
              <v-autocomplete
                  :no-filter="true"
                  clearable
                  v-model="address"
                  :items="$root.formData.locationItems"
                  :loading="isLoading"
                  :search-input.sync="lookupAddress2"
                  item-text="place_name"
                  label="Address"
                  placeholder="Start typing your address"
                  append-icon=""
                  return-object
                  auto-select-first
                  hide-no-data
                  :rules="[v => !!v  || 'This field is required!']"
                  required
              ></v-autocomplete>
              <v-menu
                  v-model="datepicker_no_result"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <!--                                            @input="search"-->
                  <v-text-field
                      id="date_no_result"
                      v-model="$root.formData.date"
                      clearable
                      label="Date (optional)"
                      readonly
                      v-on="on"
                      v-bind="attrs"
                      @click:clear="$root.formData.date=undefined"
                  ></v-text-field>
                </template>
                <v-date-picker
                    :min="tomorrow"
                    v-model="$root.formData.date"
                    :events="functionEvents"
                    @input="datepicker_no_result = false"
                >
                  <template v-slot:default>
                    <!-- legend-->
                    <div style="width: 45px;height:45px;text-align: center">
                      <button type="button" style="width:32px;height: 32px" class="v-btn v-btn--text v-btn--rounded theme--light"><div class="v-btn__content " style="margin-bottom: 4px;">1</div><div class="v-date-picker-table__events"><div class="available"></div><div class="unavailable"></div></div></button>
                    </div>
                    <div class="caption">
                      Lunch Available and Dinner unavailable
                    </div>
                  </template>
                </v-date-picker>
              </v-menu>
              <v-select
                  id="time_no_result"
                  clearable
                  :items="availableTimes"
                  label="Time (optional)"
                  v-model="$root.formData.time"
                  @click:clear="$root.formData.time=undefined"
              ></v-select>
              <v-text-field
                  id="guests_no_result"
                  label="Guests (optional)"
                  type="number"
                  min="2"
                  max="999"
                  v-model.number="$root.formData.guests"
                  :error-messages="errorGuests"
              ></v-text-field>
                <v-btn color="primary" @click="let_me_know()" :loading="isLoadingLetMeknow">Please, Let me know</v-btn>
            </div>
        </div>
      </v-form>


    </div>
</template>

<script>
    import MenuOverlay from './MenuOverlay';
    import ChefCard from "@/components/ChefCard";
    import Constants from "@/constants";

    export default {
        name: "Search",
        components: {ChefCard, MenuOverlay},
        data() {
            let tomorrowDate=new Date();
            tomorrowDate.setDate(tomorrowDate.getDate()+2);
            return {
              address:undefined,
              valid: true,
              showLetMeKnow: false,
                email:undefined,
                errorGuests:undefined,
                tomorrow:tomorrowDate.toISOString() ,
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],
                resultLocations:undefined,
                isLoading:false,
                isLoadingChef:false,
                isLoadingLetMeknow:false,
                lookupAddress:undefined,
                lookupAddress2:undefined,
                geolocation:undefined,
                formData: {
                    style: null,
                    type: null,
                    preference: null,
                    guest: 6,
                    courses: null
                },
                searchStyles: ["Traditional", "Tapas"],
                searchTypes: [
                    "Aboriginal",
                    "French",
                    "Italian",
                    "Thai",
                    "Vietnamese",
                    "Chines",
                    "Indian"
                ],
                searchPreferences: [
                    "Gluten-Free",
                    "Lactose-free",
                    "Vegetarian",
                    "Vegan",
                    "Pescaterian",
                    "Fish",
                    "Meat",
                    "Paleo",
                    "Organic"
                ],
                searchCourses: ["Appetizer", "Starter", "Desert"],
                showMenu:false,
                chefIndex:null,
                serviceIndex:null,
                datepicker:false,
                datepicker_no_result:false,
                prefixImages:"https://kootto.com/",

            };
        },
        created() {
          this.search();
        },
        computed: {
            availableDates(){
                let availableDates={};
                if (this.chefsSearch!==undefined && this.chefsSearch.length>0){
                    this.chefsSearch.forEach(chef => {
                        if (chef.events!=undefined){
                            chef.events.forEach(e => {
                                if (e.status.name==="AVAILABLE"){
                                    availableDates[e.date]=true;
                                }
                            })
                        }
                    });
                }
                this.log("available dates ",availableDates)
                return Object.keys(availableDates);
            },
          lunchAvailableDates(){
            let availableDates={};
            if (this.chefsSearch!==undefined && this.chefsSearch.length>0){
              this.chefsSearch.forEach(chef => {
                if (chef.events!=undefined){
                  chef.events.forEach(e => {
                    if (e.eventTime.name==="LUNCH" && e.status.name==="AVAILABLE"){
                      availableDates[e.date]=true;
                    }
                  })
                }
              });
            }
            return Object.keys(availableDates);
          },
          dinnerAvailableDates(){
            let availableDates={};
            if (this.chefsSearch!==undefined && this.chefsSearch.length>0){
              this.chefsSearch.forEach(chef => {
                if (chef.events!=undefined){
                  chef.events.forEach(e => {
                    if (e.eventTime.name==="DINNER" && e.status.name==="AVAILABLE"){
                      availableDates[e.date]=true;
                    }
                  })
                }
              });
            }
            return Object.keys(availableDates);
          },
            availableTimes(){
                let times=[];
                if (this.isLunchAvailable){
                    times.push.apply(times,this.$root.times.lunchTime());
                }
                if (this.isDinnerAvailable){
                    times.push.apply(times,this.$root.times.dinnerTime());
                }
                if (!this.isLunchAvailable && !this.isDinnerAvailable){
                  times.push.apply(times,this.$root.times.default());
                }
                return times;
            },
            isLunchAvailable(){
                let available=false;
                let dateSelected=this.$root.formData.date;
                if (this.chefsSearch!==undefined && this.chefsSearch.length>0){
                    this.chefsSearch.forEach(chef => {
                        if (chef.events!=undefined){
                            chef.events.forEach(e => {
                                if (e.status.name==="AVAILABLE" && e.eventTime.name==="LUNCH"){
                                    if (dateSelected!==undefined && e.date===dateSelected){
                                        available = true;
                                    } else if (dateSelected === undefined || dateSelected == null) {
                                        available = true;
                                    }
                                }
                            })
                        }
                    });
                }
                return available;
            },
            isDinnerAvailable(){
                let available=false;
                let dateSelected=this.$root.formData.date;
                if (this.chefsSearch!==undefined && this.chefsSearch.length>0) {
                    this.chefsSearch.forEach(chef => {
                        if (chef.events!=undefined){
                            chef.events.forEach(e => {
                                if (e.status.name === "AVAILABLE" && e.eventTime.name === "DINNER") {
                                    if (dateSelected !== undefined && e.date === dateSelected) {
                                        available = true;
                                    } else if (dateSelected === undefined || dateSelected == null) {
                                        available = true;
                                    }
                                }
                            })
                        }
                    });
                }
                return available;
            },
            serviceSearch(){
                let services=[];
                if (this.chefsSearch!==undefined) {
                    this.chefsSearch.forEach(chef => {
                        chef.service.forEach(ts => {
                            ts.chef = chef;
                            if (ts.enabled === true || (ts.packages && ts.packages.some(p => p.enabled === true))){
                              services.push(ts);
                            }

                        })
                    });
                }
                console.log(services)
                return services;
            },
            serviceFiltered(){
                let result = this.serviceSearch;
                if (result!==undefined){
                    for (let ts of result) {
                      ts.from = this.findFromPrice(ts);
                      ts.average = this.findAveragePrice(ts);
                      ts.packageFrom = this.findLowestPackagePrice(ts)
                      ts.available = this.isAvailableEvents(ts.chef)
                    }
                }
                result = result.sort(function (a, b) {
                  // Sort by available status, with false at the end
                  if (a.available !== b.available) {
                        return a.available ? -1 : 1;
                      }

                  if (a.packageFrom !== undefined && b.packageFrom !== undefined ){
                    return a.packageFrom - b.packageFrom;
                  }else if (a.packageFrom !== undefined && b.packageFrom === undefined ){
                    return -1;
                  }else if (a.packageFrom === undefined && b.packageFrom !== undefined ){
                    return 1;
                  }else{
                    return a.average - b.average;
                  }
                });
                return result;
            },
            chefFiltered() {
                var result = this.chefsSearch;
                if (result!==undefined){
                    for (var chef of this.chefsSearch) {
                        chef.from = this.findFromPrice(chef);
                    }
                }
                return result;
            },
            chefsSearch() {
              // let that=this;
                if (this.$store.state.chefsSearch===undefined){
                  // that.isLoadingChef=false;
                  return;
                }

                let result=this.$store.state.chefsSearch.slice();
                // that.isLoadingChef=false;
                return result;
            },
        },
        methods: {
          let_me_know(){
            if (this.validate()!=true){
              return
            }
            this.isLoadingLetMeknow=true;
            let that=this;
            this.$store.dispatch('waitingList', {"email":this.email,"address":{"fullAddress":that.address.place_name},"date":this.$root.formData.date,"time":this.$root.formData.time,"nbGuests":this.$root.formData.guests})
                .then((response) => {
                  if (response !== undefined && response.statusCode === 200) {
                      that.$store.dispatch('showSnackbar', {
                        text: Constants.LET_ME_KNOW,
                        color: "primary",
                        timeout: 0
                      })
                  }
                }).finally(()=>{that.isLoadingLetMeknow=false;});
          },
          book(serviceType,chefSelected){
            this.$root.serviceSelected=serviceType;
            this.$root.chefSelected=chefSelected;
            this.$router.push({path: `/book`});
          },
          validate () {
            if (this.$refs.form!==undefined){
              return this.$refs.form.validate();
            }
          },
            setError(field,message){
                this[field]=message;
            },
          isLunchAvailableDate(date){
            if (this.lunchAvailableDates!==undefined && this.lunchAvailableDates.includes(date) ){
              return true;
            }else{
              return false;
            }
          },
          isDinnerAvailableDate(date){
            if (this.dinnerAvailableDates!==undefined && this.dinnerAvailableDates.includes(date) ){
              return true;
            }else{
              return false;
            }
          },
          functionEvents (date) {
            let classes=[];

            if (date < this.tomorrow.substring(0,10) || !this.isLunchAvailableDate(date) && !this.isDinnerAvailableDate(date)){
              return;
            }
            if (this.isLunchAvailableDate(date)){
              classes.push("available");
            }else{
              classes.push("unavailable");
            }
            if (this.isDinnerAvailableDate(date)){
              classes.push("available");
            }else{
              classes.push("unavailable");
            }
            return classes;
          },
          checkAvailableDate(date){
            if (this.availableDates!==undefined){
                return this.availableDates.includes(date);
            }  else{
                return true;
            }
          },
          search(){
              this.isLoadingChef=true;
              let lat,lon;
              let eventTime
              if (this.$root.formData.time!==undefined){
                if (this.$root.formData.time>="06:00" && this.$root.formData.time<"11:00") {
                  eventTime ="BREAKFAST";
                } else if (this.$root.formData.time>="11:00" && this.$root.formData.time<"17:00"){
                  eventTime= "LUNCH";
                }else if (this.$root.formData.time>="17:00" && this.$root.formData.time<="23:30"){
                  eventTime= "DINNER";
                }
              }

              if (this.$root.formData.geolocation!==undefined){
                  lat=this.$root.formData.geolocation[1];
                  lon=this.$root.formData.geolocation[0];
              }
              let today=new Date();
              this.$store.dispatch('searchChefs',{lat:lat,lon:lon,today:today,date:this.$root.formData.date,eventTime:eventTime,guests:this.$root.formData.guests}).then(()=>{
                if (this.$store.state.chefsSearch===undefined){
                  console.log("result vide");
                  if (this.$root.formData.location!==undefined){
                    this.address=this.$root.formData.location;
                  }

                }
              })
              .finally(() => {
                this.isLoadingChef = false
              });

          },
          resetDate(){
              this.$root.formData.date=undefined;
              this.search();
          },
          servicePhotos(ts){
              let photos =[];
              if (ts!==undefined && ts.chef.photos!==undefined){
                  photos = ts.chef.photos.filter(photo=>( photo.serviceType.name===ts.serviceType.name));
              }
              // if (photos.length===0){
              //     if (ts.serviceType.name==='CLASSIC'){
              //         photos.push({photoPath:'../assets/',photoFilename:'course.jpg'})
              //     }else if (ts.serviceType.name==='SHARING'){
              //         photos.push({photoPath:'../assets/',photoFilename:'sharing.jpg'})
              //     }else if (ts.serviceType.name==='FUNCTION'){
              //         photos.push({photoPath:'../assets/',photoFilename:'cocktail.jpg'})
              //     }else if (ts.serviceType.name==='GRAZING'){
              //         photos.push({photoPath:'../assets/',photoFilename:'grazing.jpg'})
              //     }
              // }
              return photos;
          },
          servicePhotoDefault(ts){
              if (ts.serviceType.name==='CLASSIC'){
                  return '/img/course.jpg';
              }else if (ts.serviceType.name==='SHARING'){
                  return '/img/sharing.jpg';
              }else if (ts.serviceType.name==='FUNCTION'){
                  return '/img/cocktail.jpg';
              }else if (ts.serviceType.name==='GRAZING'){
                  return '/img/grazing.jpg';
              }
          },
          findserviceFromPrice(service) {
              // console.log(chef.name);
              var starters;
              var mains;
              var from;
              if (service!==undefined){
                  if ( service.dishes!==undefined){
                      starters=service.dishes.filter(d => (d.itemCategory.categoryType.name==="STARTER"));
                      mains=service.dishes.filter(d => (d.itemCategory.categoryType.name==="MAIN"));
                      from=0;
                      var cheapestStarter;
                      if (starters!==undefined && starters.length>0){
                          cheapestStarter = starters.slice().sort(function (a, b) {
                              return a.price - b.price;
                          });
                          from+=cheapestStarter[0].price;
                      }

                      var cheapestMain;
                      if (mains!==undefined && mains.length>0){
                          cheapestMain = mains.slice().sort(function (a, b) {
                              return a.price - b.price;
                          });
                          from+=cheapestMain[0].price;
                      }

                  }
                  // check nb guests and compare it with the minimum earnings
              }
              return from;
          },
          findAveragePrice(service) {
            if ( service!==undefined && service.enabled && service.dishes!==undefined){
              let dishes=service.dishes.filter(d => (d.itemCategory.categoryType.name!=="KID"));
              let total=0;
              for(let i = 0; i < dishes.length; i++) {
                total += dishes[i].price;
              }
              let average = total / service.dishes.length;
              return this.roundToXDigits(average,2);
            }
            return undefined;
          },
          findLowestPackagePrice(service) {
            if ( service!==undefined && service.packages!==undefined && service.packages.length>0){
              let packs=service.packages.filter(p => (p.enabled));
              let cheapest = packs.sort(function (a, b) {
                return a.price - b.price;
              })[0];
              if (cheapest!==undefined){
                return this.roundToXDigits(cheapest.price,2);
              }

            }
            return undefined;
          },
          findFromPrice(chef) {
              // console.log(chef.name);
              var classic;
              var starters;
              var mains;
              var from;
              if (chef.service!==undefined){
                  classic=chef.service.filter(ts => (ts.serviceType.name==="CLASSIC"))[0];
                  if (classic!==undefined && classic.dishes!==undefined){
                      starters=classic.dishes.filter(d => (d.itemCategory.categoryType.name==="STARTER"));
                      mains=classic.dishes.filter(d => (d.itemCategory.categoryType.name==="MAIN"));
                      from=0;
                      var cheapestStarter;
                      if (starters!==undefined && starters.length>0){
                          cheapestStarter = starters.slice().sort(function (a, b) {
                              return a.price - b.price;
                          });
                          from+=cheapestStarter[0].price;
                      }

                      var cheapestMain;
                      if (mains!==undefined && mains.length>0){
                          cheapestMain = mains.slice().sort(function (a, b) {
                              return a.price - b.price;
                          });
                          from+=cheapestMain[0].price;
                      }

                  }
              }
              return from;
          },
          isAvailableEvents(chef){
            if (!chef || !chef.events){
              return false;
            }

            let events = chef.events.filter(e => e.status.name ==="AVAILABLE" ).sort((a, b) => a.eventTime.order - b.eventTime.order);
            events.forEach(e=>{

              e.start=new Date(e.date);
              e.statusValue=e.status.name;
              e.name=e.eventTime.displayName;
              if (this.value==undefined){
                this.value=e.start;
              }
            })
            return events.length>0;
          },
          openMenu(service){
            let index=this.serviceFiltered.findIndex(s=>s.id==service.id);
              this.serviceIndex=index;
              this.chefIndex=index;
              this.showMenu=true;
              console.log(index);
              // this.hideScrollbar=true;
              this.$root.$el.parentElement.parentElement.style.overflow="hidden";
          },
          closeMenu () {
              this.showMenu = false;
              this.chefIndex=null;
              this.$root.$el.parentElement.parentElement.style.overflow="unset";
          },
          getLocation(val){
              if (val!=undefined && val!=null && this.$root.formData.location!==undefined){
                  this.$root.formData.geolocation=val.center;
                  // this.$store.dispatch('getGeolocationFullAddress',this.$root.formData.location).then(response => {
                  //     this.geolocation=response;
                  //     console.log(this.geolocation);
                  // });
              }else{
                  this.$root.formData.geolocation=undefined;
              }
              this.search();
              return this.$root.formData.geolocation;
          }
        },
        watch: {
            lookupAddress (val) {
                if (val===undefined || val===null || val.length<4) {
                    return
                }
                // Items have already been loaded
                // if (this.items !==undefined && this.items.length > 0) return

                // Items have already been requested
                if (this.isLoading) return

                this.isLoading = true

                // Lazily load input items
                let that=this;

                // using mapbox
                this.$store.dispatch('lookupAddressMapbox',val).
                then(response => {
                    that.$root.formData.locationItems=response; })
                    .finally(() => (this.isLoading = false));
            },
          lookupAddress2 (val) {
            if (val===undefined || val===null || val.length<4) {
              return
            }
            if (this.isLoading) return

            this.isLoading = true

            // Lazily load input items
            let that=this;

            // using mapbox
            this.$store.dispatch('lookupAddressMapbox',val).
            then(response => {
              that.$root.formData.locationItems=response; })
                .finally(() => (this.isLoading = false));
          },
        },

    };
</script>
